<template>
  <div>
  <div class="process_fix">
    <div class="info_tab flx flex-r">
      <div>信息公告</div>
      <span class="info_tes"><span></span></span>
    </div>
  </div>
  <div class="bus_box">
    <div class="bus_list">
      <div class="bus_cnt">
        <!--<div data-url="/index.php?g=Wap&m=Salesnet&a=index&token=aiozuv1464139085" onclick="go($(this))">-->
          <!--<img src="../../assets/imgs/icon1.png" alt="">-->
          <!--<span>营业网点</span>-->
        <!--</div>-->
        <div @click="$router.push({ name: 'WatercutInfo' })">
          <img  src="../../assets/imgs/icon2.png" alt="">
          <span>停水公告</span>
        </div>
        <div @click="$router.push({ name: 'WaterQuality' })">
          <img src="../../assets/imgs/icon3.png" alt="">
          <span>水质公告</span>
        </div>
        <div @click="$router.push({ name: 'PublicItem' })">
          <img  src="../../assets/imgs/icon4.png" alt="">
          <span>公开事项</span>
        </div>
        <div @click="$router.push({ name: 'ChargeStandard' })">
          <img  src="../../assets/imgs/icon5.png" alt="">
          <span>收费标准</span>
        </div>
      </div>
    </div>
  </div>
  </div>


</template>

<script>

import TopBar from '@/components/TopBar';
import { GoodsMixin } from '@/mixins/goodsMixin';
import ajax from '@/api';

export default {
  name: 'InfoNotice',
  mixins: [ GoodsMixin ],
  components: { TopBar},
  data() {
    return {
      commentBg: require('@/assets/imgs/banner2.jpg'),
      curTabIndex: 0,
      waitCommentList: [], // 待评价 list
      alreadyCommentList: [], // 已评价 list
    }
  },
  created() {
    this._queryWaitComment();
    this._alreadyComment();
  },
  methods: {
    /**
     * 查询待评论商品列表
     */
    async _queryWaitComment() {
      try {
        const res = await ajax.queryWaitComment();
        if (res.code === 200) this.waitCommentList = res.waitCommentList;
        this.loadingStatus = false;
      } catch (error) {
        this.loadingStatus = false;
        console.log(error);
      }
    },
    /**
     * 查询已评论商品列表
     */
    async _alreadyComment() {
      try {
        const res = await ajax.queryAlreadyComment();
        if (res.code === 200) this.alreadyCommentList = res.alreadyCommentList;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 前往商品评论
     * @param {String} goodsId 商品 id
     * @param {String} order_id 订单 _id
     * @param {String} orderNum 订单号
     */
    goCommentGoods(goodsId, order_id, orderNum) {
      this.$router.push({ name: 'CommentGoods', query: { goodsId }, params: { order_id, orderNum } });
    },
    /**
     * 查看评论详情
     * @param {String} comment_id 评论 _id
     */
    viewGoodsComment(comment_id) {
      this.$router.push({ name: 'CommentDetails', query: { commentId: comment_id } });
    }
  }
};
</script>
<style lang="scss" scoped>
    @import './style.scss';
</style>

